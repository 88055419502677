<template>
  <div class="main-container">
    <v-toolbar flat tile class="box" style="background-color: transparent">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0"> Dashboard </v-toolbar-title>
    </v-toolbar>

    <div
      class="mx-4 pb-0 box box-2 example"
      style="min-height: 100%; overflow: auto"
    >
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="3">
          <v-card class="elevation-1">
            <v-card-title class="font-weight-regular">
              <v-row justify="space-between">
                <div v-if="!isMobile()" class="mt-4">
                  <v-icon class="green--text" size="72">mdi-view-list</v-icon>
                </div>
                <div class="text-xs-right mx-2">
                  <h6 class="font-weight-regular">ออเดอร์วันนี้</h6>
                  <h3 class="display-1">{{ countOrderToday }}</h3>
                </div>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12" xs="12" sm="6" md="3">
          <v-card class="elevation-1">
            <v-card-title class="font-weight-regular">
              <v-row justify="space-between">
                <div v-if="!isMobile()" class="mt-4">
                  <v-icon class="orange--text" size="72"
                    >mdi-cube-outline</v-icon
                  >
                </div>
                <div class="text-xs-right mx-2">
                  <h6 class="font-weight-regular">ยอดขายวันนี้</h6>
                  <h3 class="display-1">{{ $currency(salePrice).format() }}</h3>
                </div>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12" xs="12" sm="6" md="3">
          <v-card
            class="elevation-1"
            @click="$router.push({ path: '/order', query: { q1: 'q1' } })"
          >
            <v-card-title class="font-weight-regular">
              <v-row justify="space-between">
                <div v-if="!isMobile()" class="mt-4">
                  <v-icon class="amber--text" size="72">mdi-cash</v-icon>
                </div>
                <div class="text-xs-right mx-2">
                  <h6 class="font-weight-regular">ยอดรับชำระวันนี้</h6>
                  <h3 class="display-1">
                    {{ $currency(priceInDay).format() }}
                  </h3>
                </div>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12" xs="12" sm="6" md="3">
          <v-card class="elevation-1">
            <v-card-title class="font-weight-regular">
              <v-row justify="space-between">
                <div v-if="!isMobile()" class="mt-4">
                  <v-icon class="green--text" size="72"
                    >mdi-briefcase-download</v-icon
                  >
                </div>
                <div class="text-xs-right mx-2">
                  <h6 class="font-weight-regular">ยอดขายวันนี้ที่ชำระแล้ว</h6>
                  <h3 class="display-1">
                    {{ $currency(paidOrderToday).format() }}
                  </h3>
                </div>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12" xs="12" sm="6" md="3">
          <v-card class="elevation-1">
            <v-card-title class="font-weight-regular">
              <v-row justify="space-between">
                <div v-if="!isMobile()" class="mt-4">
                  <v-icon class="red--text" size="72"
                    >mdi-close-box-outline</v-icon
                  >
                </div>
                <div class="text-xs-right mx-2">
                  <h6 class="font-weight-regular">ยอดขายวันนี้ที่ค้างชำระ</h6>
                  <h3 class="display-1">
                    {{ $currency(unPaidOrderToday).format() }}
                  </h3>
                </div>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>

      <v-card class="mt-3">
        <v-card-title>ยอดรับชำระรายเดือน</v-card-title>
        <apexchart
          width="100%"
          height="400px"
          type="bar"
          :options="options"
          :series="series"
        ></apexchart>
      </v-card>

      <v-card class="mt-3">
        <DashboardDailyIncome />
      </v-card>

      <v-card class="mt-3">
        <dash-board-sale />
      </v-card>

      <v-row class="mt-1">
        <v-col xs="12" md="6">
          <v-card>
            <v-card-title> ยอดสินค้าแต่ละประเภท </v-card-title>
            <v-card-text class="d-flex justify-center">
              <apexchart
                type="pie"
                width="380"
                :options="chartOptionsPie"
                :series="seriesPie"
              ></apexchart>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col xs="12" md="6">
          <v-card>
            <v-card-title> สัดส่วนรายจ่าย </v-card-title>
            <v-card-text class="d-flex justify-center">
              <apexchart
                type="pie"
                width="380"
                :options="costChartOptionsPie"
                :series="costSeriesPie"
              ></apexchart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mt-1">
        <v-col>
          <v-card>
            <v-card-title> ลูกค้ายอดสูงสุดในรอบ 1 เดือน </v-card-title>
            <v-data-table
              items-per-page="5"
              :headers="customerRangeHeaders"
              :items="customerRange"
              disable-sort
            >
              <template v-slot:item.no="{ item }">
                {{ customerRange.indexOf(item) + 1 }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title> สินค้าขายดีสุดในรอบ 1 เดือน </v-card-title>
            <v-data-table
              :items-per-page="5"
              :headers="productRangeHeaders"
              :items="productRange"
              disable-sort
            >
              <template v-slot:item.no="{ item }">
                {{ productRange.indexOf(item) + 1 }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <!--        <v-col cols="6">-->
        <!--          <v-card>-->
        <!--            <v-card-title>-->
        <!--              ยอดสินค้าแต่ละประเภท-->
        <!--            </v-card-title>-->
        <!--            <apexchart type="pie" width="380" :options="chartOptionsPie" :series="seriesPie"></apexchart>-->
        <!--          </v-card>-->
        <!--        </v-col>-->
      </v-row>

      <v-row class="mt-1">
        <v-col>
          <Dashboard_Q_Exist />
        </v-col>
      </v-row>
      <!--      <v-row dense class="box box-2" style="height: 100%;display: flex;flex-direction: row">-->
      <!--        <v-col-->
      <!--            style="height: 100%"-->
      <!--        >-->
      <!--          <v-card-->
      <!--              style="height: 100%"-->
      <!--              class="pa-2 rounded-lg elevation-2"-->
      <!--              outlined-->
      <!--              tile-->
      <!--          >-->
      <!--            <div>-->
      <!--              <apexchart width="500" type="bar" :options="options" :series="series"></apexchart>-->
      <!--            </div>-->
      <!--          </v-card>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DateTime from "luxon/src/datetime";
import Dashboard_Q_Exist from "@/components/Dashboard_Q_Exist";
import DashboardDailyIncome from "@/components/DashboardDailyIncome";
import DashBoardSale from "../components/DashBoardSale.vue";

function nameMonth(number) {
  return DateTime.now()
    .minus({ month: number })
    .setLocale("th")
    .toFormat("LLLL");
}

export default {
  components: {
    Dashboard_Q_Exist,
    DashboardDailyIncome,
    DashBoardSale,
  },
  data: () => ({
    options: {
      chart: {
        id: "vuechart-example",
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          nameMonth(11),
          nameMonth(10),
          nameMonth(9),
          nameMonth(8),
          nameMonth(7),
          nameMonth(6),
          nameMonth(5),
          nameMonth(4),
          nameMonth(3),
          nameMonth(2),
          nameMonth(1),
          nameMonth(0),
        ],
      },
    },
    series: [],
    countOrderToday: 0,
    customerRange: [],
    customerRangeHeaders: [
      {
        text: "ลำดับ",
        value: "no",
        width: "100",
      },
      {
        text: "ลูกค้า",
        value: "name",
      },
      {
        text: "ราคา",
        value: "price",
        align: "center",
      },
    ],
    productRange: [],
    productRangeHeaders: [
      {
        text: "ลำดับ",
        value: "no",
        width: "100",
      },
      {
        text: "ลูกค้า",
        value: "name",
      },
      {
        text: "จำนวน",
        value: "amount",
        align: "center",
      },
      {
        text: "หน่วย",
        value: "unit",
        align: "center",
      },
    ],
    priceInDay: 0,
    chart: null,
    priceInMonth: 0,

    seriesPie: [100],
    chartOptionsPie: null,
    categoryChart: [],

    costSeriesPie: [100],
    costChartOptionsPie: null,
    costChart: [],
    date_income_ranges: [],
    dayChart: [],
    date_income: {
      start_date: "",
      end_date: "",
    },
    salePrice: 0,
    paidOrderToday: 0,
    unPaidOrderToday: 0,
  }),
  async created() {
    let today = DateTime.now().toFormat("yyyy/LL/dd");
    let startDate = DateTime.now().minus({ month: 1 }).toFormat("yyyy/LL/dd");
    let orderToday = (
      await axios.get("/api/orders/" + `?start_date=${today}&end_date=${today}`)
    ).data.data;
    this.countOrderToday = orderToday.length;
    // this.priceInDay = orderToday.filter(o => o.payment_status === 'ชำระแล้ว').reduce((a, b) => a + b.total_price, 0)
    this.priceInDay = (
      await axios.get(
        "/api/dashboards/order_paper/" +
          `?start_date=${today}&end_date=${today}`
      )
    ).data.data.reduce((a, b) => a + b.total_price, 0);
    this.customerRange = (
      await axios.get("/api/dashboards/customer_paper/?month=1")
    ).data.data;
    this.chart = (await axios.get("/api/dashboards/expense/")).data.data;
    this.salePrice = (
      await axios.get(
        "/api/dashboards/order_item_paper/" +
          `?start_date=${today}&end_date=${today}`
      )
    ).data.price_vat;
    this.paidOrderToday = (
      await axios.get(
        `/api/dashboards/order_item_paper/?start_date=${today}&end_date=${today}&payment_status=ชำระแล้ว`
      )
    ).data.price_vat;
    this.unPaidOrderToday = (
      await axios.get(
        `/api/dashboards/order_item_paper/?start_date=${today}&end_date=${today}&payment_status=ยังไม่ชำระ`
      )
    ).data.price_vat;
    this.productRange = (
      await axios.get(
        "/api/dashboards/product_paper/" +
          `?start_date=${startDate}&end_date=${today}`
      )
    ).data.data;

    //
    // this.date_income_ranges = [DateTime.now().minus({day: 7}).toFormat('yyyy/LL/dd'), DateTime.now().toFormat('yyyy/LL/dd')]
    // if (this.date_income_ranges.length === 1) {
    //   this.date_income.start_date = this.date_income_ranges[0]
    //   this.date_income.end_date = this.date_income_ranges[0]
    // } else if (this.date_income_ranges.length === 2) {
    //   this.date_income.start_date = this.date_income_ranges[0]
    //   this.date_income.end_date = this.date_income_ranges[1]
    // }
    // console.log(this.date_income)
    // // this.dayChart = (await axios.get('/api/dashboards/income_daily/?start_date=2021/10/01&end_date=2021/10/09')).data.data
    // this.dayChart = (await axios.get('/api/dashboards/income_daily/' + `?start_date=${this.date_income.start_date}&end_date=${this.date_income.end_date}`)).data.data
    // console.log(this.dayChart)

    this.series = this.chart.y;
    this.options.xaxis.categories = this.chart.x;
    // console.log(this.options)
    // console.log(this.chart)
    // this.chartOptionsPie = {
    //   labels: ["d", "e", "f", "c"]
    // };
    this.categoryChart = (
      await axios.get("/api/dashboards/category/?month=1")
    ).data.data;
    this.seriesPie = this.categoryChart.y;
    this.chartOptionsPie = {
      labels: this.categoryChart.x,
    };
    this.costChart = (
      await axios.get("/api/dashboards/category_expense/?month=1")
    ).data.data;
    this.costSeriesPie = this.costChart.y;
    this.costChartOptionsPie = {
      labels: this.costChart.x,
    };
    console.log(this.costChart);
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
};
</script>

<style scoped>
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>