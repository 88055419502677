<template>
  <v-card class="pa-4">
    <h4 class="font-weight-regular mb-4">คิวออก</h4>
    <v-row>
      <v-col>
        <div style="margin-top: 10px">
          <v-btn depressed @click="showDateDialog=true" block class="d-flex justify-start">
            <v-icon left>mdi-calendar</v-icon>
            {{ dateRangeText }}
          </v-btn>
        </div>
      </v-col>
      <v-col>
        <v-btn @click="getData()" elevation="0" color="primary" style="margin-top: 10px">
          ค้นหาตามวัน
        </v-btn>
      </v-col>
      <v-col>
        <v-text-field v-model="nameFilter"
                      dense
                      outlined
                      placeholder="กรองชื่อสินค้า"
                      class="rounded mt-3"
                      hide-details>

        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="items" :search="nameFilter">

    </v-data-table>

    <v-dialog
        ref="dialog"
        v-model="showDateDialog"
        :return-value.sync="dates"
        persistent
        width="290px"
    >
      <v-date-picker
          v-model="dates"
          range
      >
        <v-spacer></v-spacer>

        <v-btn
            text
            color="primary"
            @click="showDateDialog = false;"
        >
          Cancel
        </v-btn>
        <v-btn
            :disabled="dates.length === 0"
            text
            color="primary"
            @click="$refs.dialog.save(dates);"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-card>
</template>

<script>
import DateTime from "luxon/src/datetime";
import axios from 'axios'

export default {
  name: "Dashboard_Q_Exist",
  data() {
    return {
      showDateDialog: false,
      searchOption: {},
      dates: [],
      backwards: 1,
      nameFilter: '',
      searchParams: {
        start_date: null,
        end_date: null
      },
      headers: [
        {
          text: 'ชื่อสินค้า',
          value: 'name'
        },
        {
          text: 'จำนวนคิวออก',
          value: 'amount'
        },
        {
          text: 'หน่วย',
          value: 'unit'
        }
      ],
      items: []
    }
  },
  created() {
    this.dates = [DateTime.now().minus({months: this.backwards}).toFormat('yyyy-LL-dd'), DateTime.now().toFormat('yyyy-LL-dd')]
    this.getData()
  },
  methods: {
    getData() {
      let dates = this.dates.map((e) => DateTime.fromFormat(e, 'yyyy-LL-dd').toFormat('yyyy/LL/dd')).sort()
      if (dates.length === 1) {
        this.searchParams.start_date = dates[0]
        this.searchParams.end_date = dates[0]
      } else if (dates.length === 2) {
        this.searchParams.start_date = dates[0]
        this.searchParams.end_date = dates[1]
      }
      axios.get('/api/dashboards/stock_back/' + this.makeQueryString())
          .then(res => {
            this.items = res.data.data
          }).catch(e => {
        console.error(e)
      })
      console.log('get data', this.searchParams)
    },
    makeQueryString() {
      let str = [];
      for (const [key, value] of Object.entries(this.searchParams)) {
        if (value) {
          str.push(key + "=" + value);
        }
      }
      return '?' + str.join("&")
    }
  },
  computed: {
    dateRangeText() {
      if (this.dates === null) {
        return ""
      } else {
        // this.dates.sort()
        return this.dates.map((e) => DateTime.fromFormat(e, 'yyyy-LL-dd').toFormat('dd/LL/yyyy')).sort().join(' - ')
      }
    },
  }
}
</script>

<style scoped>

</style>