<template>
  <v-card class="pa-4">
    <v-card-title
      >ยอดรับชำระรายวัน
      <v-spacer />
      <v-col>
        <div style="margin-top: 10px">
          <v-btn
            depressed
            @click="showDateDialog = true"
            block
            class="d-flex justify-start"
          >
            <v-icon left>mdi-calendar</v-icon>
            {{ dateRangeText }}
          </v-btn>
        </div>
      </v-col>

      <v-btn
        @click="updateChart()"
        elevation="0"
        color="primary"
        style="margin-top: 10px"
      >
        ค้นหาตามวัน
      </v-btn>
    </v-card-title>

    <apexchart
      width="100%"
      height="400px"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexchart>
    <!--    <div>-->
    <!--      <button @click="updateChart">Update!</button>-->
    <!--    </div>-->

    <v-dialog
      ref="dialog"
      v-model="showDateDialog"
      :return-value.sync="dates"
      persistent
      width="290px"
    >
      <v-date-picker v-model="dates" range>
        <v-spacer></v-spacer>

        <v-btn text color="primary" @click="showDateDialog = false">
          Cancel
        </v-btn>
        <v-btn
          :disabled="dates.length === 0"
          text
          color="primary"
          @click="$refs.dialog.save(dates)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-card>
</template>

<script>
import DateTime from "luxon/src/datetime";
import axios from "axios";

export default {
  data: function () {
    return {
      showDateDialog: false,
      searchOption: {},
      dates: [],
      searchParams: {
        start_date: null,
        end_date: null,
      },
      chartOptions: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          color: "#00e396",
          name: "series-1",
          data: [],
        },
      ],
    };
  },
  created() {
    this.dates = [
      DateTime.now().minus({ day: 7 }).toFormat("yyyy-LL-dd"),
      DateTime.now().toFormat("yyyy-LL-dd"),
    ];
    this.updateChart();
  },
  methods: {
    updateChart() {
      let dates = this.dates
        .map((e) => DateTime.fromFormat(e, "yyyy-LL-dd").toFormat("yyyy/LL/dd"))
        .sort();
      if (dates.length === 1) {
        this.searchParams.start_date = dates[0];
        this.searchParams.end_date = dates[0];
      } else if (dates.length === 2) {
        this.searchParams.start_date = dates[0];
        this.searchParams.end_date = dates[1];
      }
      axios
        .get("/api/dashboards/income_daily/" + this.makeQueryString())
        .then((res) => {
          // this.items = res.data.data
          this.series = res.data.data.y;
          this.chartOptions.xaxis.categories.splice(
            0,
            this.chartOptions.xaxis.categories.length
          );
          res.data.data.x.forEach((x) => {
            this.chartOptions.xaxis.categories.push(x);
          });
          // this.chartOptions.xaxis.categories = res.data.data.x
        })
        .catch((e) => {
          console.error(e);
        });
    },
    makeQueryString() {
      let str = [];
      for (const [key, value] of Object.entries(this.searchParams)) {
        if (value) {
          str.push(key + "=" + value);
        }
      }
      return "?" + str.join("&");
    },
  },
  computed: {
    dateRangeText() {
      if (this.dates === null) {
        return "";
      } else {
        // this.dates.sort()
        return this.dates
          .map((e) =>
            DateTime.fromFormat(e, "yyyy-LL-dd").toFormat("dd/LL/yyyy")
          )
          .sort()
          .join(" - ");
      }
    },
  },
};
</script>